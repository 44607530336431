import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import config from "../../config";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { CoachImage } from "../ui/CoachImage";
import { CommonButton } from "../ui/CommonButton";
import Panel from "../ui/Panel";
import './ObtainLicense.css';

export const ObtainLicense = () => {
    const { t } = useTranslation();

    const { trackPageView } = useAnalytics();

    useEffect(() => {
        trackPageView("/obtainLicense", "ObtainLicense");
    }, []);

    const goToAppSource = () => {
        window.open(config.appSourceUrl, '_blank');
    };

    const mailAddress = config.infoMail;
    const mailto = `mailto:${config.infoMail}`;
    const webpageLink = config.websiteUrl;

    return (
        <Container className="obtain-license-container">
            <Panel className="obtain-license-content-panel align-items-center">
                <Container>
                    <Row className="obtain-license-content">
                        <h2>
                            { t('obtainLicense.header') }
                        </h2>

                        <div className="hero-logo">
                            <CoachImage src="dashboard/coach-icon.png" />
                        </div>

                        <p>
                            { isMobile ? 
                                <Trans i18nKey="obtainLicense.body" components={{
                                    appSourceLink: <></>
                                }}/>
                            : 
                                <Trans i18nKey="obtainLicense.body" components={{
                                    appSourceLink: <CommonButton onClick={goToAppSource}>
                                        <></>
                                    </CommonButton>
                                }}/>
                            }
                        </p>

                        <p className="obtain-license-footer">
                            <Trans i18nKey="obtainLicense.footer" components={{
                                websiteLink: <a href={webpageLink}><></></a>,
                                mailLink: <a href={mailto}><></></a>
                            }} values = {{
                                address: mailAddress
                            }} />
                        </p>
                    </Row>
                </Container>
            </Panel>
        </Container>
    );
}
