import { Button } from "react-bootstrap";
import { AzureFunctions } from "../../../services/backend/AzureFunctions";
import { useContext } from "react";
import { TeamsFxContext } from "../../Context";
import { useUserInfo } from "../../../hooks/user-profile/useUserInfo";
import { CommonLoading } from "../../loading/CommonLoading";
import { useLanguage } from "../../../hooks/language/useLanguage";

export const DebugNotificationsTool = () => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { data: userInfo, isLoading: isLoadingUserInfo, error: userInfoError } = useUserInfo();
  const azureFunctions = new AzureFunctions(teamsUserCredential!);
  const locale = useLanguage().getCurrentLanguage();
  const isLoading = isLoadingUserInfo;

  if (isLoading) {
    return <CommonLoading />
  }

  if(userInfoError) {
    throw userInfoError;
  }

  const triggerSampleNotification = async () => {
    await azureFunctions.postSampleNotification(userInfo!, locale);
  };

  return <div className="mt-4">
    <Button variant="secondary" onClick={triggerSampleNotification}>Trigger sample notification</Button>
    <br />
  </div>;
};
