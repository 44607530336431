import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserIntakePageProps, UserIntakeBaseCoachPage } from "./UserIntakeBaseCoachPage";
import { useGetChapters } from "../../../hooks/chapters/UseChapters";
import { useQueryClient } from "@tanstack/react-query";
import { UserIntakeQueryKeys } from "../../../hooks/user-intake-progress/useUserIntakeProgress";
import { UserIntakeBackground } from "./UserIntakeBackground";


export const UserIntakeLoadingPage: React.FC<IUserIntakePageProps> = (props) => {
    const queryClient = useQueryClient();
    const [isVirtualLoading, setIsVirtualLoading] = useState(true);
    const { isLoading: isLoadingChapters, error: chaptersError } = useGetChapters();
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const loadingDuration = 10_000;

    if (chaptersError) {
        throw chaptersError;
    }

    setTimeout(() => {
        setIsVirtualLoading(false);
    }, loadingDuration);

    useEffect(() => {
        setIsLoading(isVirtualLoading || isLoadingChapters);
    }, [isVirtualLoading, isLoadingChapters]);


    if (!isLoadingChapters) {
        // NB: invalidate the user intake progress query to force a re-fetch
        queryClient.invalidateQueries({
            queryKey: [UserIntakeQueryKeys.UseGetUserIntakeProgress]
        });
    }

    return (
        <>
            <UserIntakeBackground isBlurred />
            <UserIntakeBaseCoachPage
                header={t('userIntake.loading.header')}
                body={t('userIntake.loading.body')}
                buttonLabel={t('userIntake.loading.buttonLabel')}
                buttonLoadingLabel={t('userIntake.loading.buttonLoadingLabel')}
                isLoading={isLoading}
                {...props} />
        </>
    );
};
