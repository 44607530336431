import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { UserIntakeLoadingPage } from "./pages/UserIntakeLoadingPage";
import { UserIntakeWelcomePage } from "./pages/UserIntakeWelcomePage";
import './UserIntake.css';
import { UserIntakeOnboardingPage } from "./pages/UserIntakeOnboardingPage";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { useGetUserIntakeProgress } from "../../hooks/user-intake-progress/useUserIntakeProgress";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { useNavigate } from "react-router-dom";
import { CommonLoading } from "../loading/CommonLoading";

interface IUserIntakeProps {
    startStep?: UserIntakeStep;
}

export enum UserIntakeStep {
    Welcome,
    Onboarding,
    Loading,
}

const stepsOrder = [
    UserIntakeStep.Welcome,
    UserIntakeStep.Onboarding,
    UserIntakeStep.Loading,
];

export const UserIntake: React.FC<IUserIntakeProps> = ({ startStep = UserIntakeStep.Welcome }) => {
    const { data: userIntakeProgress, isLoading: isUserIntakeProgressLoading, error: userIntakeProgressError, } = useGetUserIntakeProgress();
    const [currentStep, setCurrentStep] = useState(startStep);
    const { trackPageView } = useAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserIntakeProgressLoading) {
            return;
        }

        if (userIntakeProgressError) {
            console.log("Error fetching user intake progress:", userIntakeProgressError);
            trackPageView("/intake", "Intake");
            return; // User needs to give consent.
        }

        if (!userIntakeProgress) {
            trackPageView("/intake", "Intake");
            return; // User still needs to do the intake.
        }

        const isFirstRun = !userIntakeProgress!.isUserProfileReady || !userIntakeProgress!.isCurriculumReady;
        if (isFirstRun) {
            const userIntakeStep = !userIntakeProgress!.isUserProfileReady ? UserIntakeStep.Welcome : UserIntakeStep.Loading;
            setCurrentStep(userIntakeStep);

            trackPageView("/intake", "Intake");
            return; // User still needs to do the intake.
        }

        // User already did the intake, navigate to the dashboard.
        navigate(`/dashboard`);
    }, [isUserIntakeProgressLoading]);

    if (isUserIntakeProgressLoading) {
        return (<CommonLoading />);
    }

    const nextStep = () => {
        const currentIndex = stepsOrder.indexOf(currentStep);
        const nextStepIndex = currentIndex + 1;
        if (nextStepIndex < stepsOrder.length) {
            setCurrentStep(stepsOrder[nextStepIndex]);
        } else {
            navigate(`/dashboard`);
        }
    };

    const renderPage = (currentStep: UserIntakeStep) => {
        switch (currentStep) {
            case UserIntakeStep.Welcome:
                return <UserIntakeWelcomePage onNext={nextStep} />;
            case UserIntakeStep.Onboarding:
                return <UserIntakeOnboardingPage />;
            case UserIntakeStep.Loading:
                return <UserIntakeLoadingPage onNext={nextStep} />;
            default:
                throw new Error(`Unknown step: ${currentStep}`);
        }
    }

    return (
        <>
            {renderPage(currentStep)}
        </>
    );
};



