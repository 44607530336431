import { useEffect } from "react";
import { CommonLoading } from "../loading/CommonLoading";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { useGetUserIntakeProgress } from "../../hooks/user-intake-progress/useUserIntakeProgress";
import { useNavigate } from "react-router-dom";
import { AchievementsGalleryHeader } from "./AchievementsGalleryHeader";
import { AchievementsGalleryContent } from "./AchievementsGalleryContent";
import "./AchievementsGallery.css";
import { Container } from "react-bootstrap";

export const AchievementsGallery: React.FC = () => {
    const { trackPageView } = useAnalytics();
    const { data: userIntakeProgress, isLoading: isUserIntakeProgressLoading, error: userIntakeProgressError, } = useGetUserIntakeProgress();
    const navigate = useNavigate();

    const isLoading = isUserIntakeProgressLoading;

    const error = userIntakeProgressError;
    if (error) {
        throw error;
    }

    useEffect(() => {
        trackPageView("/achievements", "AchievementsGallery");
    }, []);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        const isFirstRun = !userIntakeProgress!.isUserProfileReady || !userIntakeProgress!.isCurriculumReady;
        if (isFirstRun) {
            navigate(`/intake`);
        }
    }, [isLoading]);

    if (isLoading) {
        return <CommonLoading />;
    }

    return (
        <Container fluid className="p-0">
            <AchievementsGalleryHeader />
            <AchievementsGalleryContent />
        </Container>
    );
};