import { pages } from "@microsoft/teams-js";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CoachConnector from "../../../services/coach/CoachConnector";
import { useResetUser } from "../../../hooks/user-profile/useUserInfo";
import { useLanguage } from "../../../hooks/language/useLanguage";
import { useContext } from "react";
import { TeamsFxContext } from "../../Context";

export const DebugIntakeTool = () => {
  const navigate = useNavigate();
  const { mutateAsync: resetUserAsync } = useResetUser()
  const { getCurrentLanguage } = useLanguage();
  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
  if (!teamsUserCredential) {
    throw new Error("TeamsFx SDK is not initialized.");
  }

  const coachConnector = new CoachConnector(teamsUserCredential);

  const openIntake = async () => {
    const locale = getCurrentLanguage();
    await coachConnector.startIntake(locale);
    pages.currentApp.navigateTo({ pageId: "conversations" });
  };

  const resetUser = async () => {
    await resetUserAsync();
    navigate("/");
  };

  return <div className="mt-4">
    <Button variant="secondary" onClick={resetUser}>Reset user</Button>
    <br />
    <br />
    <Button variant="secondary" onClick={openIntake}>Start intake</Button>
  </div>;
};
