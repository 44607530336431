import { Col, Container, Image, Row } from "react-bootstrap";
import "./AchievementsGallery.css"
import { Trans } from "react-i18next";
export const AchievementsGalleryHeader = () => {
    return (
        <>
            <Container className="header-container">
                <Row className="align-items-center">
                    <Col xs="auto">
                        <Image src="achievements/achievement-gallery.png" className="header-icon" />
                    </Col>
                    <Col className="header-text-col">
                    <h2 className="header-title"><Trans i18nKey="achievementsGallery.title" /></h2>
                    <p className="header-subtitle"><Trans i18nKey="achievementsGallery.subtitle" /></p>
                    </Col>
                </Row>
            </Container>
        </>
    );
};