import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { LessonStateInCurriculum } from "../../hooks/lesson-progression/LessonStateInCurriculum";
import { ICategoryProgress } from "../../hooks/lesson-progression/UseCategoryProgression";
import { LessonCategory } from "../../hooks/lesson/LessonCategory";
import { useGetLessonReferences } from "../../hooks/lesson/UseLessons";
import { CommonLoading } from "../loading/CommonLoading";
import { AchievementsGalleryLessonItem } from "./AchievementsGalleryLessonItem";
import "./AchievementsGallery.css";

export interface IAchievementsCategoryListProps {
    progressionForCategory: ICategoryProgress;
}

export const AchievementsCategoryList = ({ progressionForCategory }: IAchievementsCategoryListProps) => {
    const { t } = useTranslation();
    const { data: lessonReferences, isLoading, error } = useGetLessonReferences();

    if (error) {
        throw error;
    }

    const getCategoryName = (category: LessonCategory) => {
        return t(category.displayNameLocalizationKey);
    }

    const createLessonItem = (lessonProgress: LessonStateInCurriculum) => {
        const lessonReference = lessonReferences!.find((lessonReference) => lessonReference.id === lessonProgress.lessonId);
        return <AchievementsGalleryLessonItem lessonProgress={lessonProgress} lessonReference={lessonReference!} />;
    }

    const createCategoryIcon = (category: LessonCategory) => {
        return (
            <Image src={category.achievementsIconImageUrl} className="category-icon" />
        );
    }

    return (
        <Container>
            <hr className="category-divider" />
            <Row>
                <Col className="col-2">
                    <Row>
                        <h1 className="category-header-title">{getCategoryName(progressionForCategory.category)}</h1>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="col-6">
                            {createCategoryIcon(progressionForCategory.category)}
                        </Col>
                        <Col className="col-6 justify-content-center d-flex">
                            <span className="category-progress">
                                <Trans i18nKey={"achievementsGallery.progress"} values={{
                                    completed: progressionForCategory.completed,
                                    total: progressionForCategory.total
                                }} />
                            </span>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-10">
                    <Row className="row-cols-auto">
                        {progressionForCategory.lessons.map((lessonProgress) => (
                            <Col key={lessonProgress.lessonId} className="col-md-auto">
                                {isLoading
                                    ? <CommonLoading />
                                    : createLessonItem(lessonProgress)
                                }
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
